
.select-box {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #5f5242;
    border-radius: 4px;
    overflow: hidden;
}

.select-box .options-container {
    background: white;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    order: 1;

}

.select-box .option, .selected {
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-box .option .radio {
    display: none;
}

.selected {
    background: white;
    border-radius: 8px;
    position: relative;
    order: 0;
    /* box-shadow: 0px 10px 70px 4px rgba(0, 0, 0, 0.13); */
}


.select-box .option:hover {
    background: #8d7e6d;
}


.search-box input {
    width: 100%;
    padding: 16px 16px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    position: absolute;
    /* outline: none; */
    opacity: 0;
    outline: 1px solid #5f5242;
    outline-offset: -10px;
    left: 0;
    z-index: 100;
}

/* .search-box input:focus {
  outline: none;
} */
#option1 {
    margin-top: 45px;
}

.select-box .options-container {
    max-height: 240px;
    opacity: 1;
    overflow-y: auto;
    transition: all 0.4s;

    /* margin-top: 54px; */
}

.select-box .options-container ~ .selected::after {
    transform: rotateY(180deg);
    /* top: -6px; */
}

.select-box .options-container::-webkit-scrollbar {
    width: 7px;
    background: #5f5242;
    border-radius: 8px;
    height: 1px;
}

.select-box .options-container::-webkit-scrollbar-thumb {
    background: #8d7e6d;
    border-radius: 8px 8px 8px 8px;
}

.select-box .options-container ~ .search-box input {
    opacity: 1;
    pointer-events: auto;
}